<template>
    <div>
        <Divider dashed ><span class="divider-text">订单基础信息</span></Divider>
        <div class="p-l-10 p-r-10">
            <Row class="p-b-5">
                <i-col span="6" class="title">投放客户</i-col>
                <i-col span="18">{{orderInfo.advertiserName}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">发布品牌</i-col>
                <i-col span="18">{{orderInfo.brandName}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">创建者</i-col>
                <i-col span="18">{{orderInfo.userName}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">订单状态</i-col>
                <i-col span="18">
                  <span v-if="orderInfo.status === 0">{{orderInfo.statusName}}</span>
                  <Tag v-else :color="getOrderStatusColor(orderInfo.status).bgColor" :style="getOrderStatusColor(orderInfo.status).border ? 'border: 1px solid #fff' : 'none'">{{orderInfo.statusName}}</Tag>
                </i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">创建时间</i-col>
                <i-col span="18">{{orderInfo.createTime}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">有效期至</i-col>
                <i-col span="18">{{orderInfo.expireTime}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title p-t-5">发布日期</i-col>
                <i-col span="18"><Tag type="border" color="black">{{orderInfo.startDate}} 至 {{orderInfo.endDate}} </Tag></i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">订单备注</i-col>
                <i-col span="18">{{orderInfo.remark}}</i-col>
            </Row>
            <Row class="p-b-5">
                <i-col span="6" class="title">不确定方案</i-col>
                <i-col span="18">
                  <span v-if="orderInfo.fixed">-</span>
                  <Icon v-else type="md-checkmark" color="#19be6b" size="18" />
                  <Button v-show="isEdit && !orderBean.fixed && orderInfo.status === 4"  size="small" type="info" class="m-l-5 right" icon="md-lock" @click="handleLockPonit">锁定点位</Button>
                </i-col>
            </Row>
            <Row>
                <i-col span="6" class="title">操作</i-col>
                <i-col span="18" v-if="isAuth('order_modify_submit')">
                  <Button size="small" v-show="isEdit && (orderInfo.status === 0 || orderInfo.status === 3)" type="success" @click="handleSubmitOrder" :loading="loading">提交订单</Button>
                </i-col>
                <!-- <i-col span="18" v-if="isAuth('order_modify_locked')">
                  <Button size="small" v-show="isEdit && (orderInfo.status === 0 || orderInfo.status === 3)" type="success" @click="handleLockedOrder" :loading="loading">订单锁位</Button>
                </i-col> -->
            </Row>
        </div>
    </div>
</template>

<script>
import { beforeSubmit, submitOrder, lockOrder, finishOrder } from '@/api/order/order'
import { getOrderStatus } from '@/utils/tagStatus'

export default {
  props: {
    orderBean: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderInfo: {},
      loading: false
    }
  },
  created () {
    this.orderInfo = this.orderBean
  },
  methods: {
    getOrderStatusColor (status) {
      return getOrderStatus(status)
    },
    handleSubmitOrder () {
      this.loading = true
      beforeSubmit({ orderId: this.orderBean.id }).then(res => {
        const status = res.orderStatusValue
        let lblcontent = ''
        if (status === 3) { // 先签先得
          lblcontent = '请注意：当前订单开始档期为 临近档期或<b style="color:orange">采购主题媒体</b>资源，将不锁定点位，订单状态为： <b class="text-red">' + res.orderStatusName + '</b>'
        } else {
          lblcontent = '您提交的订单点位将被锁定，订单状态为： <b class="text-red">' + res.orderStatusName + '</b>，锁定截止时间：' + res.expireTime
        }

        this.$Modal.confirm({
          title: '确认要提交订单吗？',
          content: lblcontent,
          loading: true,
          onOk: () => {
            this.$Modal.remove()
            this.SubmitOrder()
          }
        })

        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    SubmitOrder () { // 提交订单
      const postData = { orderId: this.orderBean.id }

      submitOrder(postData).then(res => {
        this.$store.dispatch('getOrderBaseInfo', postData)
        this.$store.commit('set_adx_pageType', 'detail')
        this.$Notice.success({ desc: '订单提交成功' })
      })
    },
    handleLockPonit () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要锁定当前订单点位吗？点位锁定后，将不再允许变更！',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }

          lockOrder(postData).then(res => {
            this.$store.dispatch('getOrderBaseInfo', postData)
            this.$store.commit('set_adx_pageType', 'detail')
            this.$Notice.success({ desc: '订单锁位成功！' })
          })
        }
      })
    },
    handleLockedOrder () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认要锁定当前订单点位吗？点位锁定后，将不再允许变更！',
        onOk: () => {
          const postData = { orderId: this.orderBean.id }
          finishOrder(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$store.dispatch('getOrderBaseInfo', postData)
              this.$store.commit('set_adx_pageType', 'detail')
              this.$Notice.success({ desc: '订单锁位成功' })
            }
          })
        }
      })
    }
  },
  watch: {
    orderBean (val) {
      this.orderInfo = this.orderBean
    }
  }
}
</script>
